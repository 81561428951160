.media-wrapper {
  @include media-wrapper();
}

.media {
  transition: transform 10s linear;
  max-width: none;
  backface-visibility: hidden;
}

.media-wrapper__footer {
  position: absolute;
  bottom: 0;

  width: 100%;

  background: rgba(white, 0.2);

  color: $body-font-color;
}
