.chip {
  cursor: inherit !important;
  font-size: rem-calc(13);
  border-radius: 50%;
  padding: rem-calc(7) rem-calc(12);

  &--big {
    font-size: rem-calc(16);
  }
  & span {
    transition: 0.2s all;
  }
}

.chip.chip--status {
  font-size: rem-calc(10);
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: uppercase;
  padding: rem-calc(2) 0 rem-calc(1) 0;
  height: auto;
  color: $text-primary;
  background-color: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.07);
  &.success {
    @extend .bg--success;
    color: white;
  }
  &.info {
    @extend .bg--info;
    color: white;
  }
  &.danger {
    @extend .bg--danger;
    color: white;
  }
  &.warning {
    @extend .bg--warning;
  }
  &.condensed span {
    font-size: 0;
  }
}
