.page-body {
    &>.container {
        @include mk-flexbox($grid:true);
        margin: 0 auto;
        padding-top: rem-calc($gutter-base*2);
        padding-bottom: rem-calc($gutter-base*2);

        @include breakpoint(xs only) {
            padding-top: rem-calc($gutter-base);
            padding-bottom: rem-calc($gutter-base);

        }

    }
}

.page__content {
    padding: 0 rem-calc($gutter-base/2);

}

.single,
.page {

    & .page-body {

        &>.container {
            margin-top: -16vh;


            @include breakpoint(xs only) {
                margin-top: -20vh;
            }
        }

        &.about {
            background-color: white;

            &>.container {
                margin-top: 0;
            }

            & .page__content {
                @include breakpoint(md) {
                    width: 90%;
                }
            }

            & .featured_img {
                margin: rem-calc(-$gutter-base*4) 0 rem-calc($gutter-base);
                width: 100%;
                float: right;

                @include breakpoint(md) {
                    max-width: rem-calc(600);
                    margin-right: -10%;
                    margin-left: rem-calc($gutter-base);
                }
            }

            @include breakpoint(sm down) {
                &>.container {
                    &>.grid__container {
                        flex-direction: column-reverse;
                    }
                }
            }
        }
    }

}


.page__title {
    font-size: rem-calc(26);
    font-weight: 300;
    margin-bottom: rem-calc(20);
}