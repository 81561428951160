.section {
  background-color: inherit;

  &>.container {
    @include mk-flexbox($grid: true);
    margin: 0 auto;
    min-height: 30vh;
    padding-top: rem-calc($gutter-base*3);
    padding-bottom: rem-calc($gutter-base*3);
  }

  &--related {
    &>.container {
      padding-top: 0;
    }
  }

  &--features {
    background-color: lighten(get-color(primary), 50%);

    &>.container {
      min-height: 50vh;
    }

    & .section__title {
      font-weight: bolder;
      font-size: rem-calc(36);
    }

    @include breakpoint(md down) {
      &>.container {
        padding: rem-calc($gutter-base*1.5);
      }
    }
  }

  // Sizes
  &--min-height {
    min-height: 60vh;
  }

  &--fullscreen {
    min-height: 100vh;
  }

  &--halfscreen {
    height: 50vh;
  }

  &--dark-filter:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.5);
  }

  &--filter:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: darken(rgba(get-color(secondary), 0.35), 30%);
  }

  &--extend-prev .section__container {
    padding-top: rem-calc(25);
  }

  &.--primary-dark,
  &.--primary-darker {
    color: lighten($color: get-color(primary), $amount: 85%);
  }

  &.--primary-dark .section__title,
  &.--primary-darker .section__title {
    color: lighten($color: get-color(primary), $amount: 75%);
  }

  &--white {
    background-color: white;
  }

  &--glass {
    background-color: transparent;
  }
}

.section__icon {
  max-width: rem-calc(30);
  display: inline-block;
  margin-bottom: rem-calc(10);
}

.section__icon--empty {
  font-size: rem-calc(60);
  opacity: .2;
  max-width: none;
}

.section__spacer {
  padding-bottom: rem-calc(35);
}

.section__footer {
  padding-top: rem-calc($gutter-base*2);
}

.section__body,
.section__footer,
.section__header {
  padding-bottom: rem-calc($gutter-base*2);

  &:last-child {
    padding-bottom: 0;
  }

  @include breakpoint(xs only) {
    padding-bottom: rem-calc($gutter-base/2);
  }
}

.section__header {
  @include mk-flexbox(column, stretch, center);

  &:only-child {
    padding-bottom: 0;
  }

  & .section__icon {
    padding-bottom: 0;
  }

  & .section__header-content {}
}

.section--item-detail {
  & .section__container {
    padding-top: 0;
    margin-top: -7vh;
  }

  &+.section .section__container {
    padding-top: 0;
  }
}

/* ------------------------------------
GRID
------------------------------------- */
.section__grid {
  @include mk-flexbox($flow: row wrap, $justify: space-between);
  @include mk-gutter-xy(margin, -35);
  width: auto;

  &+.section__grid {
    flex-direction: row-reverse;
    padding-top: rem-calc(50);
  }
}

.section__grid-item {
  @include mk-gutter-xy(padding, 35);
  width: 100%;
}

.section__row {
  margin-bottom: rem-calc(25);
}

.section__title {
  font-size: rem-calc(26);
  font-weight: 300;
  color: $text-primary;
  margin: 0;
}

.section__subtitle {
  @extend p, .small;
  color: $medium-gray;

  &--small {
    @extend p, .smaller;
  }
}



.section__tagline {
  text-align: center;
  margin-top: rem-calc(5);

  &--highlight {
    font-size: rem-calc(15);
    text-transform: uppercase;
    font-weight: 700;
    color: $dark-gray;
    position: relative;
  }
}

/*
-------------------------------------------------------------------------------
MEDIA QUERIES 
-------------------------------------------------------------------------------
*/

// @include breakpoint(xlarge down) {
//   .section__title {
//     font-size: rem-calc(50);
//   }
// }

@include breakpoint(md down) {
  .section--fullscreen {
    align-items: center;
  }

  .section__img {
    height: auto;
  }
}

@include breakpoint(xs only) {

  .section__container {
    padding: rem-calc(15) rem-calc(10);
  }

  .section__grid {
    @include mk-gutter-xy(margin, -12.5);
  }

  .section__grid-item {
    @include mk-gutter-xy(padding, 12.5);
    text-align: center;
  }

  .section__row {
    margin-bottom: rem-calc(30);
  }

  //   .section__title {
  //     align-self: center;
  //     text-align: center;
  //     font-size: rem-calc(20);
  //     margin-top: rem-calc(25);

  //     &:after {
  //       margin-left: auto;
  //       margin-right: auto;
  //     }
  //   }

  .section__text {
    text-align: center;
  }

  .section--item-detail .section__container {
    margin-top: -12vh;
    padding-left: rem-calc(20);
    padding-right: rem-calc(20);
    padding-bottom: rem-calc(20);
  }
}