.form {
  width: 100%;
  max-width: rem-calc(600);
  margin: 0 auto;
}

.form-control {
  display: inline-flex;
  position: relative;
  margin-bottom: rem-calc(20);

  text-align: left;
  font-size: rem-calc(15);
  color: $text-primary;

  transition: .2s all;


  &.is-active,
  &.is-filled {
    .form-control__label {
      transform: translate(0, 0) scale(0.65);
      transform-origin: top left;
      top: 0;
      left: 0;
      font-style: normal;
    }
  }


  &.is-active {
    &:after {
      border-bottom-width: 2px;
    }

    .form-control__label {
      color: get-color(primary);
      opacity: 1;
    }
  }

  &:hover {
    &:after {
      border-bottom-width: 2px;
    }
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border: 1px solid transparent;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgba(black, .2);
    transition: .2s all;
  }

  &.error {
    margin-bottom: rem-calc(40);

    &:after {
      border-bottom-color: get-color(alert);
    }
  }

}

.form-control__input {
  width: 100%;
  background: transparent;
  border: none;
  color: inherit;
  font-size: inherit;
  padding: rem-calc(8);
  margin-top: rem-calc(20);
}

.form-control__label {
  position: absolute;
  transform: translate(0, rem-calc(23)) scale(1);

  top: 0;
  left: 0;

  opacity: .5;
  font-size: inherit;
  transition: .2s all;

  font-style: italic;
}

.form-control__btn {
  color: inherit;
  cursor: pointer;
}

.form-control__action-btns {
  @include mk-flexbox($align:flex-start);
  margin: rem-calc(-4);

  & .action-btn {
    margin: rem-calc(4)
  }
}

.form-control__error {
  position: absolute;
  bottom: rem-calc(-20);
  font-size: rem-calc(12);
  color: get-color(alert);
  width: 100%;
  display: none;
  transition: all .2s ease-in-out;
}

.form-control.error {
  & .form-control__error {
    display: block;
  }
}

.form-control__captcha {
  position: relative;

  &:after {
    border-bottom-width: 0 !important;
  }

  &>div {
    display: inline-block;
    position: relative;
  }
}

button[type='submit'] {
  &:after {
    content: '';
    background-image: url('../../img/bars.gif');
    background-position: 98%;
    background-repeat: no-repeat;
    background-size: contain;
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
}

form.loading {
  & button[type='submit'] {
    padding-right: rem-calc(60);

    &:after {
      opacity: 1;
      display: block;
    }
  }
}

.form__group {
  text-align: left;
  position: relative;
  margin-bottom: rem-calc(15);

  border: 1px solid transparent;
  // border-bottom: 1px solid rgba(black,.2);
  border-bottom: 1px solid rgba(black, .2);

  // background: $white;


  & input,
  input:focus,
  textarea,
  textarea:focus,
  select:focus {
    border: none;
    padding: rem-calc(25) rem-calc(8) rem-calc(8);
    margin: 0;
    background: transparent;
    width: 100%;
    color: inherit;
  }

  & .form-error-list {
    padding: rem-calc(7);
    background-color: rgba(darken(get-color(alert), 5%), .1);
  }

  &--with-icon {
    & .form__label {
      left: rem-calc(40)
    }
  }

  &.is-filled:not(.is-active),
  &.is-active {
    & .form__label {
      transform: translate(0, 0) scale(0.65);
      transform-origin: top left;
      top: 0;
      left: 0;
      font-weight: bold;
      text-transform: uppercase;
      font-style: normal;
    }
  }

  &.is-active {
    & .form__label {
      opacity: 1;
      font-style: normal;
      // color: get-color(primary);
    }

    // border-bottom-color:get-color(primary);
  }

  &.is-filled:not(.is-active) {
    & .form__label {
      opacity: .5;
      font-style: normal;
      // color: $medium-gray;
    }
  }

  & .fa {
    color: $medium-gray;
    padding-right: rem-calc(5);
    width: auto;

    &+input {
      display: inline-block;
      max-width: 80%;
      width: auto;
    }
  }

  &.is-invalid-input:not(.is-filled):not(.is-active) .form__label {
    top: 50%;
  }

}


.form__label {
  position: absolute;
  top: 65%;
  left: rem-calc(8);
  transform: translateY(-50%);

  font-size: inherit;
  font-style: italic;
  color: inherit;
  opacity: .7;

  // letter-spacing: 1px;

  margin-bottom: 0;
  z-index: 0;

  transition: all .2s;

  cursor: text;
}

.form__grid-wrapper {
  width: 100%;
}

.form__grid {
  @include mk-flexbox($flow: row wrap);
  @include mk-gutter($Xgutter: margin, $Xvalue:-10, $Ygutter:margin, $Yvalue:0);
  position: relative;
  // margin-bottom: rem-calc(7.5);
}

.form__grid-item {
  display: block;
  @include mk-gutter($Xgutter: padding, $Xvalue:10, $Ygutter:padding, $Yvalue:0);
  width: 100%;
  position: relative;
}

.form__footer {
  @include mk-flexbox($flow: row, $justify:flex-end);
}

.form__input {
  margin-bottom: 0;
}

.form__btn {
  @extend .btn;

  &--submit {
    @extend .btn, .floater;
    background: get-color(primary);

    &:hover,
    &:focus,
    &:active {
      background: get-color(primary);
    }
  }

  &--cancel {
    background: rgba(black, .2);
    margin-right: rem-calc(15);
  }
}

.form-error {
  margin: 0;
}

.form__terms-text {
  display: block;
  margin-bottom: rem-calc(15);
}

.forms__terms-url {
  color: get-color(primary);
}

@include breakpoint(xs only) {
  .form {
    padding-bottom: rem-calc(50);
  }

  .form__footer {
    justify-content: center;
  }

}