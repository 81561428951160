.slider {
    cursor: move;

    &--home {
        & .slide {
            height: 90vh;
        }
    }

    & .slide {
        overflow: hidden;
        position: relative;
        height: 100vh;

        &.has-content {
            @include add-filter($angle: 180, $iniColor: #394557, $iniTransp:.2, $endColor: #252b34, $endTransp:.35);
        }
    }
}


.slide__img {
    width: 100%;
    height: inherit;
    max-height: inherit;
    object-fit: cover;
    object-position: center center;
    z-index: -1;
}

.slide__link {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.slide__content {
    position: absolute;

    text-align: center;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%);
}

.slide__title {
    font-size: rem-calc(36);
    font-weight: bold;
}

.slide__text {
    font-size: rem-calc(18)
}

.slide__title,
.slide__text {
    color: white;
}

.slide__btn {
    @extend .btn,
    .btn--primary,
    .floater;
    margin-top: rem-calc($gutter-base);
}