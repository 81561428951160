.ads {
  & > .container {
    @extend .grid__container;
  }

  @include breakpoint(sm) {
    & .ads__item {
      max-width: 25%;
    }

    & .ads__item:nth-child(1) {
      max-width: 55%;
    }

    & .ads__item:nth-child(2) {
      max-width: 45%;
    }

    & .ads__item:nth-child(1),
    & .ads__item:nth-child(2) {
      & .ad {
        &:before {
          padding-bottom: rem-calc(275);
        }
      }
    }
  }

  @include breakpoint(sm down) {
    & .ads__item {
      max-width: 50%;
    }

    & .ads__item:nth-child(1),
    .ads__item:nth-child(2) {
      max-width: 100%;

      & .ad {
        @include mk-ratio('wide');
      }
    }
  }
}

.ads__item {
  @extend .grid__item;
  width: 100%;
}

.ad {
  @extend .card, .media-wrapper, .media-wrapper--square;
}
