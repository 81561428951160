@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@mixin mk-flexbox(
  $flow: column,
  $align: stretch,
  $justify: flex-start,
  $grid: false,
  $width: auto
) {
  display: flex;
  flex-flow: $flow;
  align-items: $align;
  justify-content: $justify;
  position: relative;
  width: $width;

  @if $flow==row {
    flex-wrap: wrap;
  }

  /*
    If $grid is set true, it sets the max-width to $global-width previously set in _settings.scss
    and use it to create the lateral padding when the resize gets closer to the grid. 
    */
  @if $grid==true {
    max-width: $global-width;

    @media all and (max-width: calc($global-width + ($global-width-padding * 2))) {
      padding-left: calc($global-width-padding/2);
      padding-right: calc($global-width-padding/2);
    }
  }
}

@mixin mk-gutter(
  $Xgutter: margin,
  $Xvalue: 5,
  $Xtype: rem,
  $Ygutter: margin,
  $Yvalue: 0,
  $Ytype: rem
) {
  @if $Xtype== '%' {
    $Xvalue: percentage($Xvalue/100);
  } @else {
    $Xvalue: rem-calc($Xvalue);
  }

  @if $Ytype== '%' {
    $Yvalue: percentage($Yvalue/100);
  } @else {
    $Yvalue: rem-calc($Yvalue);
  }

  #{$Xgutter}-left: $Xvalue;
  #{$Xgutter}-right: $Xvalue;
  #{$Ygutter}-top: $Yvalue;
  #{$Ygutter}-bottom: $Yvalue;
}

@mixin mk-gutter-xy($gutter: margin, $value: 5, $type: rem) {
  @if $type== '%' {
    $value: percentage($value/100);
  } @else {
    $value: rem-calc($value);
  }

  #{$gutter}-left: $value;
  #{$gutter}-right: $value;
  #{$gutter}-top: $value;
  #{$gutter}-bottom: $value;
}

@mixin mk-color-contrast($color, $pct, $force-color-white: false) {
  $bg-color: smart-scale($color, $pct);
  background-color: $bg-color;

  @if $force-color-white==true {
    color: white;
  } @else {
    $text-pct: $pct + 20;
    color: smart-scale($bg-color, $text-pct);
  }
}

/*
Mixin to create a color pallete to element, text and _title
If $class-name is set, it creates a class with all pallete
scheme
*/
@mixin mk-element-pallete(
  $color,
  $class-name: '',
  $add-pct: 0,
  $white-text: false
) {
  $txt-color: black;
  $bg-color: smart-scale($color, $add-pct * 1%);

  @if $white-text==true {
    $txt-color: white;
  } @else {
    $txt-pct: $add-pct + 35;

    @if $txt-pct>100 {
      $txt-pct: 100;
    }

    $txt-color: smart-scale($bg-color, $txt-pct * 1%);
  }

  @if $class-name != '' {
    .#{$class-name} {
      background-color: $bg-color;
      color: $txt-color;

      & > [class*='__title'] {
        color: lighten($txt-color, 20);
      }
    }
  } @else {
    background-color: $bg-color;
    color: $txt-color;

    & > [class*='__title'] {
      color: lighten($txt-color, 20);
    }
  }
}

/* ----------------------------------------------------------------------------
Gradient
-------------------------------------------------------------------------------
Just a prototype for speed gradient creation without 
having to visit colorzilla 
*/
@mixin mk-gradient(
  $baseColor: black,
  $iniColor: '',
  $endColor: '',
  $iniTransp: 1,
  $endTransp: 1,
  $angle: 90
) {
  @if ($iniColor== '') {
    $iniColor: $baseColor;
  }

  @if ($endColor== '') {
    $endColor: $baseColor;
  }

  background: $baseColor;
  background: -moz-linear-gradient(
    $angle + deg,
    rgba($iniColor, $iniTransp) 0%,
    rgba($endColor, $endTransp) 100%
  );
  /* FF3.6-15 */
  background: -webkit-linear-gradient(
    $angle + deg,
    rgba($iniColor, $iniTransp) 0%,
    rgba($endColor, $endTransp) 100%
  );
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    $angle + deg,
    rgba($iniColor, $iniTransp) 0%,
    rgba($endColor, $endTransp) 100%
  );
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$iniColor, endColorstr=$endColor, GradientType=0);
  /* IE6-9 */
}

/* ----------------------------------------------------------------------------
Before Background
-------------------------------------------------------------------------------
Snippet to create a pseudo-element :before as a filter
Usually used to hover photos
*/
@mixin add-filter(
  $bg-color: black,
  $opacity: 1,
  $opacity-hover: $opacity,
  $position: before,
  $iniColor: '',
  $endColor: '',
  $iniTransp: 1,
  $endTransp: 1,
  $angle: 90
) {
  &:#{$position} {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    // background: $bg-color;
    @include mk-gradient(
      $bg-color,
      $iniColor,
      $endColor,
      $iniTransp,
      $endTransp,
      $angle
    );
    opacity: $opacity;
    transition: all 0.4s;
  }

  &:hover:#{$position} {
    opacity: $opacity-hover;
  }
}

/* ----------------------------------------------------------------------------
Ratio
-------------------------------------------------------------------------------
Creates aspect ratio by adding pseudo elements :before and :after

*/
@mixin mk-ratio($ratio: '4x3') {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;

  $padding: 75%;

  @if $ratio== 'square' {
    $padding: 100%;
  }

  @if $ratio== 'wide' {
    $padding: 9/16 * 100%;
  }

  @if $ratio== 'portrait' {
    $padding: 16/9 * 100%;
  }

  &:before,
  &:after {
    content: '';
    display: table;
    width: 1px;
    margin-left: -1px;
  }

  &:before {
    padding-bottom: $padding;
    float: left;
    clear: both;
  }
}

@mixin mk-spacing() {
  $gutters: (8, 16, 24, 32, 40);

  @each $gutter in $gutters {
    .grid__container.spacing-#{$gutter} {
      width: auto;
      margin: rem-calc(-$gutter/2);

      & > .grid__item {
        padding: rem-calc($gutter/2);
      }
    }
  }

  @each $gutter in $gutters {
    @each $name, $size in $breakpoints {
      @include breakpoint($name) {
        .grid__container.spacing-#{$name}-#{$gutter} {
          margin: rem-calc(-$gutter/2);

          & > .grid__item {
            padding: rem-calc($gutter/2);
          }
        }
      }
    }
  }
}

@mixin mk-button-pallete() {
  @each $name, $color in $foundation-palette {
    .btn--#{$name} {
      color: $color;
      background-color: transparent;

      &:hover,
      &:focus,
      &:active {
        background-color: rgba($color, 0.1);
        color: $color;
      }

      &.flat,
      &.raised,
      &.floater {
        background-color: $color;
        color: color-pick-contrast($base: $color, $tolerance: 20);

        &:hover,
        &:focus,
        &:active {
          background-color: darken($color, 8%);
          color: color-pick-contrast($base: $color, $tolerance: 20);
        }
      }

      &.outlined {
        background-color: transparent;
        color: $color;
        border: 1px solid $color;

        &:hover,
        &:focus,
        &:active {
          background-color: rgba($color, 0.1);
        }
      }

      &:disabled {
        cursor: not-allowed;
        background-color: $light-gray !important;
        color: $medium-gray !important;
      }
    }
  }
}

@mixin mk-opacity-classes() {
  $max: 95;
  $interval: 5;

  @for $i from 0 through $max {
    .op-#{$i} {
      opacity: $i/100;
    }

    $i: $i + $interval;
  }
}

@include mk-opacity-classes();

@mixin media-wrapper() {
  background: transparent;
  backface-visibility: hidden;
  overflow: hidden;

  @include mk-ratio();

  &--square {
    @include mk-ratio('square');
  }

  &--portrait {
    @include mk-ratio('portrait');
  }

  &--wide {
    @include mk-ratio('wide');
  }

  &--circle {
    @include mk-ratio('square');
    border-radius: 100%;
  }

  & .media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    object-fit: cover;
    background-position: center center;
    background-size: cover;

    &--contain {
      object-fit: contain;
      max-width: 100%;
      height: 100%;
    }
  }
}
