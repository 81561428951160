html {
  min-height: 100%;
}

body {
  font-family: $body-font-family;
  min-height: 100vh;

  position: relative;
  overflow-x: hidden;
  line-height: 1.4;
  background-color: $body-background-color !important;
}

html,
body {
  height: 100%;
}

iframe {
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  font-family: $body-font-family;
}

small {
  transition: 0.2s all;
}

/* Charts */
// tspan {

// }
// .chart__area .recharts-area-area {
//   fill: get-color(info);
//   stroke: darken(get-color(info), 30);
// }

// path.recharts-curve.recharts-line-curve {
//   stroke: get-color(alert);
// }
// .recharts-cartesian-axis {
//   opacity: 0.35;
// }
// g.recharts-layer.recharts-cartesian-axis-tick {
//   font-size: rem-calc(12);
// }
label,
label * {
  font-family: $body-font-family;
}

a {
  transition: color 0.3s;
  text-decoration: none;
  color: get-color(primary);

  &:hover {
    color: inherit;
  }
}

main {
  flex: 1;
}

.maintenance {
  & main {
    @include mk-flexbox($justify:center)
  }
}

.main-wrapper {
  @include mk-flexbox($justify: space-between);
  min-height: 100vh;
}


p {
  text-align: inherit;
  margin-top: 0;
  margin-bottom: rem-calc(16);
  font-size: $global-font-size;

  &.big {
    font-size: 120%;
  }

  &.bigger {
    font-size: 140%;
  }

  &.small {
    margin-bottom: rem-calc(10);
  }

  &.smaller {
    margin-bottom: rem-calc(8);
  }

  &:only-child,
  &:last-child {
    margin-bottom: 0;
  }
}

.small {
  font-size: 90% !important;
}

.smaller {
  font-size: 80% !important;
}

ul {
  padding-left: rem-calc(20);
}

*:focus {
  outline: none;
}

.text-mutted {
  color: $medium-gray;
}

.xs-collumn-reverse {
  @include breakpoint(xs only) {
    flex-direction: column-reverse;
  }
}

.pointer {
  cursor: pointer;
}

@include breakpoint(retina) {
  @include breakpoint(xs only) {
    html {
      font-size: 103%;
    }
  }

  @include breakpoint(xl) {
    html {
      font-size: 103%;
    }
  }
}

/* --------------------------------------------------
Wrapper
-------
Override any wrapper
-------------------------------------------------- */
.wrapper {
  transition: 0.2s all;
  width: 100%;
  background: transparent !important;
  color: inherit !important;
  max-height: 100%;
  position: relative;
  display: block;

  &--hidden {
    overflow: hidden;
  }
}

.grid-wrapper {
  @extend .wrapper,
  .wrapper--hidden;
}

.date-setter__buttons {
  position: relative;
  padding: rem-calc(10);

  &.monthly {

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 1px;
      height: 7px;
      border-left: 1px solid rgba(black, 0.5);
      left: 50%;
      transform: translateX(-50%);
    }

    &:before {
      bottom: 0;
    }

    &:after {
      top: 0;
    }
  }
}

.slick-slide {}

.slick-track {
  @include breakpoint(xs only) {
    display: flex;
  }
}

/* --------------------------------------------------
Map
-------------------------------------------------- */
#map {
  width: 100%;
  height: 60vh;
}

/* --------------------------------------------------
Parallax
-------------------------------------------------- */
.parallax {
  height: inherit;
  min-height: 60vh;
  width: inherit;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;

  @include breakpoint(xl) {
    background-position: center center;
    background-attachment: fixed;
  }
}


/* --------------------------------------------------
Aspect Ratio
-------------------------------------------------- */
%ratio {
  @include mk-ratio();

  & %ratio__container,
  & %ratio__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  & %ratio__bg {
    object-fit: cover;
    background-position: center center;
    background-size: cover;
  }

  &--square {
    @include mk-ratio('square');
  }

  &--circle {
    @include mk-ratio('square');
    border-radius: 100%;
  }

  &--portrait {
    @include mk-ratio('portrait');
  }

  &--wide {
    @include mk-ratio('wide');
  }
}

.remove-ratio {
  & .media-wrapper {
    height: 100%;
  }
}

.hoverFadeIn {
  opacity: 0.5;
  transition: all 0.2s;

  &:hover {
    opacity: 1;
  }
}

.shrink {
  height: auto;
  max-height: 100vh;
  overflow: hidden;
  transition: all 0.5s;

  &.in {
    max-height: 0;
  }
}

.defaultIconClass {
  opacity: 0.45;
}

.expand-icon.expanded {
  transform: rotate(180deg);
}



.quote {
  @include mk-flexbox(row, $align: center, $justify: flex-start);
  background: rgba(0, 0, 0, 0.02);
  position: relative;
  margin-bottom: rem-calc(16);

  & .content {
    padding: rem-calc(5);
    font-size: 90%;
    z-index: 1;
    font-style: italic;
  }

  & .icon {
    z-index: 0;
    width: rem-calc(48);
    height: rem-calc(48);
    opacity: 0.15;
    position: relative;
    float: left;

    &.open {
      top: 0;
      left: 0;
      transform: rotate(180deg);
    }

    // &.close {
    //   bottom: 0;
    //   right: 0;
    // }
  }
}

.compare {
  @include mk-flexbox(row, $justify: flex-start, $align: center);
}

.compare__item {}

.compare__icon {
  margin-left: rem-calc(7);
  display: inline-block;

  & svg {
    display: inline-block;
    vertical-align: middle;
    font-size: rem-calc(20);

    @include breakpoint(xs only) {
      font-size: rem-calc(16);
    }
  }

  @include breakpoint(xs only) {
    margin-left: rem-calc(4);
  }
}

.compare__pct {
  display: inline-block;
  vertical-align: middle;
  font-size: rem-calc(13);
  font-weight: 600;
  opacity: 0.65;
}

@include breakpoint(md down) {
  [data-aos-delay] {
    transition-delay: 0s !important;
  }
}