.menu {
    position: fixed;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    top: 0;
    left: 0;

    color: white;

    width: 100%;
    min-height: 100vh;
    height: 100%;

    transition: .2s all;

    & [data-aos] {
        opacity: 1 !important;
        transition: none;
        transform: none;
    }

    &.showing {
        opacity: 1;
        z-index: 999;
        visibility: visible;

        & .menu__item--main {
            height: 70vh;
        }

        & .menu__item--footer {
            height: 30vh;
        }
    }

    &>.container {
        height: 100%;
    }

    & .close-btn {
        z-index: 9;
        color: white;
        font-size: rem-calc(20);
        opacity: .7;
        transition: .2s all;

        &:hover {
            opacity: 1;
        }
    }

    & .logo {
        height: rem-calc(40);
        filter: brightness(0) invert(1);

        @include breakpoint(xs only) {
            height: rem-calc(30);
        }
    }

    & .nav {
        @include mk-flexbox(row, $justify: space-between);
        margin: rem-calc(-$gutter-base/2);

        & .nav__item {
            padding: rem-calc($gutter-base/2);
        }
    }
}

.menu__item {
    position: absolute;
    transition: .3s all ease-in-out;
    width: 100%;
    overflow: hidden;
    z-index: 1;

    &--main {
        background: get-color(primary);
        height: 0;
        top: 0;
    }

    &--footer {
        height: 0;
        bottom: 0;

        & .footer {
            height: 100%;
        }
    }
}

.menu__logo-container {
    @include mk-flexbox($justify: center);
    height: 100%;
}

.menu__search {
    font-size: 150%;
    width: 100%;
    margin-top: rem-calc($gutter-base);

    color: white;
    caret-color: white;

    & input:-webkit-autofill,
    & input:-webkit-autofill:hover,
    & input:-webkit-autofill:focus input:-webkit-autofill,
    & textarea:-webkit-autofill,
    & textarea:-webkit-autofill:hover,
    & textarea:-webkit-autofill:focus,
    & select:-webkit-autofill,
    & select:-webkit-autofill:hover,
    & select:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px get-color(primary) inset !important;
        -webkit-text-fill-color: $white !important;
        -webkit-tap-highlight-color: $white !important;
    }

    & .form-control__label {
        color: white !important;
    }

    & .form-control__input {
        color: $text-primary;
    }

    &:after {
        border-bottom-color: rgba(white, .5);
    }

    @include breakpoint(xs only) {
        font-size: 120%;
    }
}

.menu__btn {
    font-size: rem-calc(22);
    line-height: 1.5;
    margin-bottom: rem-calc(15);
    font-weight: 600;
    position: relative;
    display: inline-block;

    & a {
        color: white;
    }

    cursor: pointer;

    &:hover {
        &:after {
            opacity: 1;
        }
    }

    &:after {
        content: '';
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        border-bottom: 1px solid white;
        opacity: 0;
        transition: .2s all;
    }

    @include breakpoint(xs only) {
        font-size: (20);
    }
}

@include breakpoint(sm down) {

    .menu.showing {
        overflow-y: scroll;

        & .nav {
            flex-direction: column;
        }

        & .menu__item {
            position: relative;
            height: auto;

        }

        & .menu__content {
            padding: rem-calc($gutter-base) rem-calc($gutter-base/2);
        }

        & .menu__search {
            margin-top: 0;
        }
    }



}