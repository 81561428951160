.topbar {
  @include add-filter($angle: 180, $iniColor: #394557, $iniTransp:.1, $endColor: #252b34, $endTransp:0);
  position: fixed;
  width: 100%;

  top: 0;
  left: 0;

  border-bottom: 1px solid rgba(black, .05);
  background: transparent;

  transition: all .5s !important;
  z-index: 9;

  &>.container {
    @include mk-flexbox(row, $justify: space-between, $align:center, $grid:true);
    height: rem-calc(80);
    margin: 0 auto;
  }

  .nav {
    @include mk-flexbox(row, $justify:space-around);
  }

  .nav__item {
    flex: 1;
    color: white;
    cursor: pointer;
    padding: rem-calc(15);

    & a {
      color: inherit;
    }
  }

  & .logo {
    filter: brightness(0) invert(1);
  }

  &.sticky {
    @extend %depth-1;
    background: get-color(primary);
  }

}